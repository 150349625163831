
export const cards = [
  {
    image: process.env.PUBLIC_URL + "/images/downtown-austin-skyline.jpg",
    title: "UT City CoLab",
    content: "Cities like Austin, TX has faced hardships due to changes in climate and extreme weather events. The University of Texas and the City of Austin have come together for a collaboration agreement to develop a framework to tackle these challenges related climate and weather. ",
    link: "https://www.utcitycolab.org/",

  },

  {
    image: process.env.PUBLIC_URL + "/images/UTwin_dataview.png",
    title: "Atmospheric and Urban Digital Twins",
    content: "Atmospheric and urban digital twins (ADTs and UDTs) are two emerging technologies that have the potential to revolutionize the way we understand and manage our cities.By combining ADTs and UDTs, scholars, decision-makers, and stakeholders can better comprehend the effects of climate change and urban development on air quality, water supply, and other factors of the built environment. This knowledge can then be used to inform collective planning and evidence-based decision-making, leading to more efficient and sustainable urban development.   ",
    link: "/audt"

  },

  {
    image: process.env.PUBLIC_URL + "/images/Umbrella.jpeg",
    title: "Join Our Team!",
    content: "Interested in a MS/Ph.D. position with our group? Send an email to happy1@utexas.edu."
  },



  



  // Add more cards here
];