export const teamMates
 = [
  {
    name: "Dev Niyogi",
    image: process.env.PUBLIC_URL + "/images/Dev-Pic-Aug2024.jpg",
    title: "Professor and Williams Stamps Chair",
    bio: "Professor and William Stamps Farish Chair, Department of Earth and Planetary Sciences, Jackson School of Geosciences, and Department of Civil, Architectural and Environmental Engineering, Cockrell School of Engineering, Affiliate Faculty: Oden Institute for Computational Engineering and Sciences, and UT Center for Space Research, The University of Texas at Austin",
    linkedin:"https://www.linkedin.com/in/texus",
    website: "https://niyogi.dev",
    email:"dev.niyogi@jsg.utexas.edu",
    projectsCategory: ["Lead PI"] 
  },
  {
    name: "Hassan Dashtian",
    image:process.env.PUBLIC_URL +"/images/Hassan.jpeg",
    title: "Research Faculty in BEG",
    bio: "",
    projectsCategory: ["Hurricanes, AI/ML"] 

  },
  {
    name: "Manmeet Singh",
    image: process.env.PUBLIC_URL + "/images/manmeet.png",
    title: "Distinguished Postdoctoral Fellow",
    bio: "Manmeet Singh is a Distinguished Postdoctoral Fellow at the Jackson School of Geosciences, UT Austin, with over a decade of experience in climate science. His research focuses on land, ocean, and atmospheric interactions, using AI/ML techniques and mathematical models. He contributed to the IITM Earth System Model for the IPCC AR6 report and pioneered AI-driven advancements in weather prediction. Manmeet has received prestigious fellowships and actively engages in academic outreach, leading research projects on climate data science and applications like flood forecasting and urban climate resilience",
    email:"manmeet.singh@utmail.utexas.edu",
    website:"https://manmeet3591.github.io/",
    linkedin:"https://www.linkedin.com/in/manmeet-singh-60bb6640/",
    projectsCategory: ["AI/ML, UT-Meteogan"] 
  },
  {
    name: "Allysa Dallmann",
    image:process.env.PUBLIC_URL +"/images/Allysa.png",
    title: "Program Coordinator",
    bio: "Allysa has a background in Meteorology and Climate Dynamics. The core of her position is to work hand and hand with the UT City Climate CoLab to understand what are the needs in city departments and how we can provide information and climate data tools to them for their operations and local decision making here in Austin.",
    linkedin:"https://www.linkedin.com/in/allysa-dallmann/",
    email:"dallmann@utexas.edu",
    projectsCategory: ["UT-City Climate CoLab, NASA Austin CARES, NIST"] 
  },
  {
    name: "Alexia Leclercq",
    image: process.env.PUBLIC_URL + "/images/alexia.png",
    title: "UT-City  CoLab Project Manager",
    bio: "Alexia works with UT and various city departments in creating and utilizing localized climate data to advance climate resilience, adaptation, and mitigation. With a background in grassroots environmental justice organizing, federal climate policy, and participatory research Alexia brings experience from working in academia, politics, and frontline communities to foster meaningful collaboration for change. Her notable achievements include pushing for an equitable fossil fuel phase out at the UN, successful campaigns against the petrochemical industry's expansion, contributing to climate and land use laws, protecting the Colorado River, and confounding an environmental justice education non-profit, Start:Empowerment, which has reached thousands of youth across the U.S. Additionally, Alexia has received prestigious awards such as the Grist 50 Fixer, Brower Youth Award, Harvard AOCC Award, WWF Conservation Award and been featured on platforms such as Forbes, The Washington Post, Now This News, and The Guardian. She holds a bachelor degree in environmental science and politics from the Gallatin School of Individualized Study at New York University and a masters degree from the Harvard Graduate School of Education.",
    email:"alexia.leclercq@utexas.edu",
    linkedin:"https://www.linkedin.com/in/alexia-leclercq/",
    projectsCategory: ["UT-City Climate CoLab, NASA Austin CARES, NIST"] 
  },
  {
    name: "Alka Tiwari",
    image: process.env.PUBLIC_URL + '/images/Alka.png',
    title: "Postdoctoral Fellow",
    bio: `Alka Tiwari is a hydrometeorologist who studies how satellite-based rainfall estimates impact hydrology, especially during extreme weather events like hurricanes. Currently, she is working on an NSF RAPID proposal to assess rainfall for Hurricane Helene, along with evaluating the variability in gridded rainfall products and their effects on hydrological models. She has a background in Civil Engineering and earned her Doctoral degree from Purdue University.

<br><br><a href="https://orcid.org/0000-0003-0194-839X" target="_blank" rel="noopener noreferrer">ORCID Profile</a> <br> <a href="https://tiwarialka.github.io/" target="_blank" rel="noopener noreferrer">Personal Website</a>`,
    email:"alka.tiwari@jsg.utexas.edu",
    linkedin:"https://www.linkedin.com/in/alkapurdue/",
    website:"https://tiwarialka.github.io/",
    projectsCategory: ["Hurricanes, Satellite, Hydrology"] 
  },
  {
    name: "Koorosh Azizi",
    image: process.env.PUBLIC_URL + "/images/Koorosh_2.png",
    title: "Postdoctoral Fellow",
    bio: "Koorosh Azizi is a Postdoctoral Fellow at the Jackson School of Geosciences. His research focuses on the interdisciplinary aspects of water and environmental systems, including the impacts of climate risk on infrastructure and communities, decision-making processes, coupled human-natural systems, policy analysis, sustainability, as well as mitigation and adaptation strategies.",
    email:"koorosh.azizi@austin.utexas.edu",
    website:"https://scholar.google.com/citations?user=MXlzhmcAAAAJ&hl=en",
    linkedin:"https://www.linkedin.com/in/koorosh-azizi-058367b2",
    projectsCategory: ["CRESSLE, Water and Network Analysis"] 
  },
  {
    name: "Naveen Sudharshan",
    image: process.env.PUBLIC_URL +"/images/naveen.png",
    title: "Postdoctoral Fellow",
    bio: "",
    email:"naveen.sudharsan@jsg.utexas.edu",
    projectsCategory: ["AI/ML, Digital Twins, Heat, Hurricanes, ThermalScapes"] 
  },
  {
    name: "Shyama Mohanty",
    image: process.env.PUBLIC_URL + "/images/Shyama_1.png",
    title: "Postdoctoral Fellow",
    email:"shyama.mohanty@jsg.utexas.edu",
    bio: "Shyama Mohanty is a postdoctoral fellow at the Jackson School of Geosciences with a specialized focus on modeling extreme weather phenomena, including hurricanes, monsoon depressions, and thunderstorms. Her research centers on developing and employing high-resolution ocean-land-atmosphere model (IOLA) built on state-of-the-art framework HWRF. Through her work, Shyama aims to enhance our understanding of complex weather systems and improve predictive models, ultimately contributing to better preparedness and resilience in the face of severe weather events.",
    linkedin:"https://www.linkedin.com/in/shyama-mohanty-38224389/",
    projectsCategory: ["Hurricanes, Hurricane NOAA Modeling"] 
  },
  {
    name: "Harsh Kamath",
    image: process.env.PUBLIC_URL + '/images/Harsh.png',
    title: "PhD Student",
    bio: "I am a PhD student working on urban meteorology. My primary focus is urban heat, urban dataset generation using AI/ML methods, development of urban parametrizations for offline and weather models. I also have a keen interest in urban boundary layer processes.",
    linkedin:"https://www.linkedin.com/in/harshkamath",
    email:"harsh.kamath@utexas.edu",
    projectsCategory: ["Urban Weather and Extremes Modeling, UT-GLOBUS"] 
  },
  {
    name: "Rakshith Mallesh",
    image: process.env.PUBLIC_URL + "/images/rakshith.jpg",
    title: "1st Year PhD Student",
    bio:"Rakshith is a PhD candidate in the Department of Earth and Planetary Sciences at UT Austin. He joined the TExUS lab in the Fall of 2024. His research aims to study the dynamics of Atmospheric Rivers over land in the context of land-atmosphere interactions. He has a master’s degree in Physics from the Indian Institute of Science Education and Research (IISER) Berhampur. Prior to joining UT, he conducted research at the Indian Institute of Science (IISc), where he worked with Prof. Jayaraman Srinivasan. There, he spent time working on understanding the mechanisms of decadal rainfall variability in the past and the future over the Indian subcontinent and the Sahel. He is interested in all things related to tropical climate, including the physics of monsoons, the Inter Tropical Convergence Zone (ITCZ), hurricanes, and atmospheric rivers.",
    email:"rakshith@utexas.edu",
    linkedin:"https://www.linkedin.com/in/rakshithm01/",
    website:"https://rakshith-mallesh.github.io/",
    projectsCategory: ["Atmospheric Rivers, Tropical Systems"] 
  },
  {
    name: "Xinxin Sui",
    image: process.env.PUBLIC_URL + '/images/Sui_2.png',
    title: "PhD Student, NASA Fellow",
    bio: "Sui, Xinxin is a PhD candidate in the Environmental and Water Resources Engineering program at UT Austin. She has been named as a Future Investigator in NASA Earth and Space Science and Technology (FINESST) since 2022. She works with prof. Dev Niyogi and prof. Zong-Liang Yang in the extreme weather and urban sustainability (TExUS) lab. Her doctoral research investigates global urban rainfall anomalies using remote sensing datasets.",
    email:"xs3737@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/xinxin-sui-9b2673151/",
    projectsCategory: ["Extreme Urban Rainfall"] 

  },
  {
    name: "Trevor Brooks",
    image: process.env.PUBLIC_URL + '/images/Trevor.png',
    title: "PhD Student, NASA Fellow",
    bio: "I am a PhD student in the Jackson School of Geosciences. I was named a Future Investigator in NASA Earth and Space Science and Technology (FINESST) in 2024. I work with prof. Dev Niyogi in the The Extreme Weather and Urban Sustainability (TExUS) lab. I am also co-advised by prof. Patrick Bixler at the LBJ School of Public Affairs. My interests are the intersection between policy and extreme weather with a focus on extreme heat and climate adapatation policies. ",
    email:"tb35259@my.utexas.edu",
    linkedin:"http://www.linkedin.com/in/trevorbrooks43",
    projectsCategory: ["Urban Heat, Satellite Data, NASA Central TX Atlas"] 

  },
  {
    name: "Arya Chavoshi",
    image: process.env.PUBLIC_URL + '/images/Arya.png',
    title: "PhD Student",
    bio: "Arya is a Ph.D. student at the Cockrell School of Engineering. He earned his bachelor's degree in Civil Engineering from Sharif University of Technology, focusing on stochastic hydrology and statistical methods for water resource management. His Ph.D. research centers on Scientific Machine Learning and probabilistic modeling for statistical downscaling of remote sensing data and climate projections. He developed PINN-SM, a physics-informed neural network for estimating root zone soil moisture from surface satellite data.",
    email:"arya.chavoshi@utexas.edu",
    linkedin:"https://www.linkedin.com/in/arya-chavoshi/",
    projectsCategory: ["Extreme Urban Rainfall, Soil Moisture, AI/ML models"] 

  },
  {
    name: "Ting-Yu Dai",
    image: process.env.PUBLIC_URL + '/images/Ting-Yu.png',
    title: "PhD Student",
    bio: "I am a PhD student working on machine learning. My primary focus is ML for building energy modeling. I am also interested in applying computer vision models on remote sensing data such as precipitation or aerosol.",
    email:"td25238@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/ting-yu-dai-1abb1a1a1/",
    website:"https://www.funnyengineer.com/",
    projectsCategory: ["Digital Twins and AI, Building Energy"] 
  },
  {
    name: "Sasanka Talukdar",
    image: process.env.PUBLIC_URL + '/images/Sasanka.png',
    title: "PhD Student",
    bio: "Sasanka is a PhD student in the Department of Earth and Planetary Sciences at the Jackson School of Geosciences, University of Texas at Austin. His research focuses on understanding the physical processes that drive extreme weather events. Currently, he is investigating the influence of the brown ocean effect and convective cold pools on hurricane dynamics. His work integrates the use of dynamical models, such as the Hurricane Weather Research and Forecasting (HWRF) model, along with observational data to gain insights into these phenomena.",
    email:"st37357@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/sastalukdar/",
    website:"https://sastalukdar.github.io",
    projectsCategory: ["Hurricanes, Monsoon Systems, Satellite Datasets, Convection"] 
  },
  {
    name: "Dhanuj Gandikota",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "PhD Student",
    bio:"",
    email:"",
    linkedin:"",
    projectsCategory: ["Urban Fire Modeling"] 
  },
  {
    name: "Amanda Pfeil",
    image: process.env.PUBLIC_URL + '/images/Amanda_2.png',
    title: "Masters Student",
    bio: "Amanda has a background in Geography and Sustainability, and is a graduate student in the Energy and Earth Resources Program. Her work focuses on urban tree canopy as a Nature-Based Solution for carbon and thermal mitigation.",
    linkedin:"https://www.linkedin.com/in/amandapfeil3",
    email:"apfeil@utexas.edu",
    projectsCategory: ["Urban Tree Canopy, Nature Based Solutions"] 
  },
  {
    name: "Calvin Lin",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "PhD Student",
    bio:"",
    linkedin:"",
    email:"",
    projectsCategory: ["Digital Twins and AI for Austin and Energy management"] 
  },
  {
    name: "Philip Mauri",
    image: process.env.PUBLIC_URL + '/images/philip_headshot.jpg',
    title: "Masters Student",
    bio:"Philip is a first-year dual masters student in the LBJ School of Public Affairs and the Cockrell School of Engineering concurrently pursuing a MS in Civil Engineering (Sustainable Systems) and a Master of Public Affairs. He has a Bachelors of Science in Civil and Environmental Engineering from Princeton. Philip is studying the evolution of the urban heat island effect in Austin using historical near-surface meterological observation records.",
    linkedin:"https://www.linkedin.com/in/philip-maruri-047304223/",
    email:"pmaruri@utexas.edu",
    projectsCategory: ["Urban Heat Island"] 
  },
  {
    name: "Aditya Patel",
    email:"aditya.patel@utexas.edu",
    image: process.env.PUBLIC_URL + "/images/Adi.png",
    title: "Masters Student",
    bio: "Aditya is a Master's student at the Oden Institute of Computational Sciences majoring in Computational Science, Engineering and Mathematics. He is currently working on applications of Large Language Models in the realms of efficient information retrieval.",
    email:"aditya.patel@utmail.utexas.edu",
    projectsCategory: ["LLM for Water, Data integration"] 
  },
  {
    name: "Parika Grover",
    image:process.env.PUBLIC_URL +"/images/Parika.jpg",
    title: "Web and Product Developer",
    bio: "Parika is a skilled Web and Product Developer currently working with TexusLab and UT-City Climate CoLab. She specializes in designing and developing products to build and maintain responsive, functional, and user-friendly platforms.",
    email:"parika.grover@jsg.utexas.edu",
    linkedin:"https://www.linkedin.com/in/parika-grover-955627166",
    website:"https://parikagrover.wixsite.com/portfolio",
    projectsCategory: ["UT-City Climate CoLab"] 
  },
  {
    name: "Morgan Kim",
    image: process.env.PUBLIC_URL + '/images/morgan_profile.jpeg',
    title: "Creative Coordinator and Designer",
    bio:"Morgan is a two-time UT-graduate, currently serving as the Creative Coordinator and Graphic Designer for the extreme weather and urban sustainability (TExUS) lab and the UT-City Climate CoLab.",
    linkedin:"https://www.linkedin.com/in/morgantkim/",
    email:"morgan.kim@austintexas.gov",
    projectsCategory: ["UT-City Climate CoLab"] 
  },
  {
    name: "TBD",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "Data and Community Partnerships",
    bio:"",
    linkedin:"",
    email:"",
    projectsCategory: ["UT-City Climate CoLab, NIST, NASA CARES"] 
  }, 
  {
    name: "Anant Dimiri",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "GEO Undergraduate Researcher",
    bio:"",
    linkedin:"",
    email:"",
    projectsCategory: ["Urban Rainfall, Data Tools"] 
  },
  {
    name: "Hannah Risinger",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "Environmental Engineering Undergraduate Researcher",
    bio:"",
    linkedin:"",
    email:"",
    projectsCategory: ["Cloud - Convection Modeling"] 
  },
  {
    name: "Cecilia Castillo",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "ECE Undergraduate Researcher",
    bio:"",
    linkedin:"",
    email:"",
    projectsCategory: ["Dynamic System  Modeling, UT-City Climate CoLab"] 
  },
  {
    name: "Brant Melton",
    image: process.env.PUBLIC_URL + '/images/defaultpic.png',
    title: "Oden Undergraduate Researcher",
    bio:"",
    linkedin:"",
    email:"",
    projectsCategory: ["Climate Downscaling with Gen AI"] 
  },

  // Add more postdoc members here
];