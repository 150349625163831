import React, { useEffect, useState } from 'react';
import ResearchCardGrid from '../ResearchCardGrid';
import Footer from '../Footer';
import Content from '../Content';
import './OngoingProjects.css';
import { Link } from 'react-router-dom';
import yaml from 'js-yaml'; // Import js-yaml library
import { useNavigate, useParams } from 'react-router-dom';

function OngoingProjects() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const { category } = useParams();
  const decodedCategory = decodeURIComponent(category).toLowerCase().replace(/_/g, ' ').replace(/-/g, '+').replace(/%2F/g, '/');
  const navigate = useNavigate();

  // Category-specific images
  const categoryImages = {
    'city_research_colab': '/images/city_research.png',
    'ai_ml_digital_twin_efforts': '/images/AI_ML_cover.png',
    'hurricanes_and_extreme_weather': '/images/hurricane1.png',
    'international_partnerships': '/images/international_partnership.png',
  };

  const categoryLabels = {
    'city_research_colab': 'City Research + CoLab',
    'ai_ml_digital_twin_efforts': 'AI/ML Digital Twin Efforts',
    'hurricanes_and_extreme_weather': 'Hurricanes and Extreme Weather',
    'international_partnerships': 'International Partnerships',
  };


  useEffect(() => {
    const yamlFilePath = process.env.PUBLIC_URL + '/Projects.yaml';

    fetch(yamlFilePath)
      .then((response) => response.text())
      .then((yamlData) => {
        const parsedProjects = yaml.load(yamlData);
        setProjects(parsedProjects);

        if (category) {
          // Filter projects based on the category from URL
          const filtered = parsedProjects.filter(
            (project) => project.Category === category
          );
          setFilteredProjects(filtered);
        } else {
          setFilteredProjects(parsedProjects); // If no category in the URL, show all projects
        }
      })
      .catch((error) => {
        console.error('Error fetching or parsing YAML file:', error);
      });
  }, [category]);

  const handleCategoryClick = (category) => {
    // Navigate to the category-specific route
    navigate(`/OngoingProjects/${category}`);
  };


  return (
    <>
      <div style={{ background: "#f7f7f7" }}>
        <div className="header-banner">
          <div className="wall-container">
            <video className="bg-video" autoPlay muted loop playsInline>
              <source src={process.env.PUBLIC_URL + '/videos/drone-video.mp4'} />
            </video>
          </div>
          <h1>Ongoing Projects</h1>
        </div>


        {!category ? (
          <div className="category-boxes">
            {['city_research_colab', 'ai_ml_digital_twin_efforts', 'hurricanes_and_extreme_weather', 'international_partnerships'].map(
              (categorySlug) => (
                <div
                  key={categorySlug}
                  className="category-box"
                  onClick={() => handleCategoryClick(categorySlug)}
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + categoryImages[categorySlug]})`,
                  }}
                >
                  <div className="category-overlay">
                    <h2>{categoryLabels[categorySlug]}</h2> {/* Use the human-readable name here */}
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="cardsgrid">
          <ResearchCardGrid cards={filteredProjects} />
        </div>
        )}
      </div>
    {/* <div style={{background:"#f7f7f7"}}>
    <div className="header-banner">
    <div className="wall-container">
        <video className="bg-video" autoPlay muted loop  playsInline>
          <source src={process.env.PUBLIC_URL + '/videos/drone-video.mp4'} />
        </video>
        </div>
         <h1>Ongoing Projects</h1>
    </div>
      <div className="cardsgrid">
        <ResearchCardGrid cards={projects} />
      </div>
      </div> */}

       {/* 
    This is a multi-line comment.
    <p>You can comment out elements like this, too.</p>
  

      <div className="ongoing-projects-page">
        {projects.map((project, index) => (
          <div key={index}>
            <Content
              title={project.title}
              pictureSrc={project.pictureSrc}
              content={project.longContent}
              targetSection={project.targetSection}
            />
            <Link to={`/content/${project.targetSection}`}>Read More</Link>
          </div>
        ))}
      </div>
      */}

      <Footer />
    </>
  );
}

export default OngoingProjects;